export const environment = {
  commonClientCode: 'common',
  production: false,
  apiBaseUrl: 'https://api.rosalie.pixacare.com',
  appInsights: {
    instrumentationKey: 'a07e3360-2028-4657-8f74-7b435fe8c869',
  },
  cdn: {
    clientBaseUrl: 'https://cdn.pixacare.com/clients/',
  },
  firebaseCloudFunctionsBaseUrl: 'https://europe-west1-pixacare-staging.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyD5tfcIxjLVfxyxgenTYHQPwQfILDUYTXQ',
    authDomain: 'pixacare-staging.firebaseapp.com',
    databaseURL: 'https://pixacare-staging.firebaseio.com',
    projectId: 'pixacare-staging',
    storageBucket: 'pixacare-staging.appspot.com',
    messagingSenderId: '785146295481',
    appId: '1:785146295481:web:c49c890f006f9e59e61768',
    measurementId: 'G-RE7HZVFMCR',
  },
  omnidoc: {
    newTeUri: 'https://playground.omnidoc.fr/interop/drafts/new#',
  },
  hubspot: {
    formUrl: 'https://www.pixacare.com/formulaire-support-client',
  },
};
